<script setup lang="ts">
const user = useSupabaseUser();

const letsGo = () => {
  if (user.value) {
    navigateTo("/classes");
  } else {
    navigateTo("/classes");
  }
};
</script>

<template>
  <div class="flex min-h-screen justify-center">
    <div
      class="grid grid-cols-1 md:grid-cols-2 justify-between hero bg-base-200 my-8"
    >
      <div class="hero-img flex justify-center items-center mx-8">
        <img
          src="/img/wlearn.webp"
          class="w-full md:w-full xl:p-16 rounded-3xl"
        />
      </div>
      <div
        class="hero-content flex items-start md:items-center md:max-w-xl justify-center text-center"
      >
        <div class="w-full">
          <h1 class="text-5xl font-bold">Light of Learning!</h1>
          <p class="py-6 mr-8">
            Future bright minds, ready to glow? Our games will light your way to
            becoming a school superstar!
          </p>
          <UButton
            color="primary"
            active-color="primary"
            variant="solid"
            active-variant="outline"
            size="xl"
            block
            class="cursor-pointer rounded-lg p-3 text-xl w-1/2"
            @click="letsGo"
            >Let's go!</UButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
